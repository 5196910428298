<template>
  <div class="page">
    <!-- swiper -->
    <div class="container no-banner">
      <VxCard>
        <h2 class="text-left mb-5">Négociateur</h2>

        <!-- Agent Détails -->
        <VsTable v-if="agentInformations" :data="agentInformations">
            <VsTr v-for="information in agentInformations" :key="information.name">
              <VsTd class="headTab">{{ information.title }}</VsTd>
              <VsTd>{{ information.value }}</VsTd>
            </VsTr>
        </VsTable>

        <!-- Cancelled requests statistics -->
        <VxCard title="Nombre de demandes d'annulation du négociateur" class="mt-10 mb-10">
          <!-- CHART -->
          <div slot="no-body">
            <VsRow>
              <VsCol
                vs-type="flex"
                vs-justify="center"
                vs-align="left"
                vs-lg="3"
                vs-sm="12"
                vs-xs="12"
                class="space-10"
              >
                <span style="margin-right: 20px">Année</span>
                <VsInput type="text" v-model="annee" class="w-full" />
              </VsCol>
              <VsCol
                vs-type="flex"
                vs-justify="left"
                vs-align="left"
                vs-lg="3"
                vs-sm="12"
                vs-xs="12"
                class="space-10"
              >
                <VsButton class="mr-3 mb-2" @click="filterStatut()">
                  Filtrer
                </VsButton>
              </VsCol>
            </VsRow>
            <VueApexCharts
              type="bar"
              height="200"
              :options="adminAnalytics.demandesStatutPie.chartOptions"
              :series="formuleSerie"
            />
          </div>

          <!-- CHART DATA -->
          <ul>
            <li
              v-for="commandData in commandesFormule"
              :key="commandData.formule"
              class="flex mb-3 justify-between"
            >
              <span class="flex items-center">
                <span
                  class="
                    inline-block
                    h-4
                    w-4
                    rounded-full
                    mr-2
                    bg-white
                    border-3 border-solid
                  "
                  :class="`bg-${commandData.formule}`"/>
                <span class="font-semibold">{{ commandData.formule }}</span>
              </span>
              <span>{{ commandData.count }}</span>
            </li>
          </ul>
        </VxCard>

        <h2 class="text-left mb-5">Demande d'annulation</h2>

        <!-- Cancelled request detail & option -->
        <VsTable v-if="demandeAnnulation" class="mb-10" :data="Object.values(demandeAnnulation)">
          <!-- Status row -->
          <VsTr>
            <VsTd class="headTab">Statut</VsTd>
            <VsTd>
              <select class="vs-select w-full" v-model="demandeAnnulation.statut">
                <option
                  v-for="item in optionsStatut"
                  :key="item"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>
            </VsTd>
          </VsTr>

          <!-- request date row -->
          <VsTr v-if="demandeAnnulation.dateDemande">
            <VsTd class="headTab">Date de la demande</VsTd>
            <VsTd>{{ demandeAnnulation.dateDemande | formDate }}</VsTd>
          </VsTr>

          <!-- rdv link row -->
          <VsTr v-if="demandeAnnulation.rendezVous">
            <VsTd class="headTab">Rendez-Vous</VsTd>
            <VsTd>
              <router-link
                :to="{
                  name: 'admin-appointments-id',
                  params: { id: demandeAnnulation.rendezVous.id }
                }"
              >
                {{ demandeAnnulation.rendezVous.id }}
              </router-link>
            </VsTd>
          </VsTr>

          <!-- announcement reference row  -->
          <template v-if="demandeAnnulation.rendezVous && demandeAnnulation.rendezVous.ad">
            <VsTr>
              <VsTd class="headTab">Annonce</VsTd>
              <VsTd>{{ demandeAnnulation.rendezVous.ad.reference }}</VsTd>
            </VsTr>

            <!-- Seller username row -->
            <VsTr>
              <VsTd class="headTab">Vendeur</VsTd>
              <VsTd>{{ demandeAnnulation.rendezVous.ad.nomVendeur }}</VsTd>
            </VsTr>

            <!-- Seller phone row -->
            <VsTr>
              <VsTd class="headTab">Numéro de télephone</VsTd>
              <VsTd>{{ demandeAnnulation.rendezVous.ad.mobileNumber }}</VsTd>
            </VsTr>

            <!-- Seller address row -->
            <VsTr>
              <VsTd class="headTab">Adresse du bien</VsTd>
              <VsTd>
                {{ demandeAnnulation.rendezVous.ad.adresseBien }} <br />
                {{ demandeAnnulation.rendezVous.ad.postalCode }}
                {{ demandeAnnulation.rendezVous.ad.adCity }}
              </VsTd>
            </VsTr>
          </template>

          <!-- order link row -->
          <VsTr v-if="demandeAnnulation.commande">
            <VsTd class="headTab">Commande</VsTd>
            <VsTd>
              <router-link
                :to="{
                  name: 'admin-orders-id',
                  params: { id: demandeAnnulation.commande.id }
                }"
              >
                {{ demandeAnnulation.commande.reference }}
              </router-link>
            </VsTd>
          </VsTr>

          <!-- Reason row -->
          <VsTr v-if="demandeAnnulation.motif">
            <VsTd class="headTab">Motif</VsTd>
            <VsTd>
              {{ demandeAnnulation.motif }}
            </VsTd>
          </VsTr>

          <!-- Comment row -->
          <VsTr v-if="demandeAnnulation.commentaire">
            <VsTd class="headTab"> Commentaire </VsTd>
            <VsTd>{{ demandeAnnulation.commentaire }}</VsTd>
          </VsTr>

          <!-- Seller met row -->
          <VsTr v-if="demandeAnnulation.vendeurRencontre">
            <VsTd class="headTab"> Vendeur rencontré </VsTd>
            <VsTd>
              {{ demandeAnnulation.vendeurRencontre | ouiNonFilter }}
            </VsTd>
          </VsTr>

          <!-- RDV confirmed row -->
          <VsTr v-if="demandeAnnulation.rdvConfirme">
            <VsTd class="headTab">
              Rendez-vous confirmé par le vendeur
            </VsTd>
            <VsTd >
              {{ demandeAnnulation.rdvConfirme | ouiNonFilter }}
            </VsTd>
          </VsTr>

          <!-- Admin comment row -->
          <VsTr v-if="demandeAnnulation.commentaireAdmin">
            <VsTd class="headTab">Commentaire de l'administrateur</VsTd>
            <VsTd>
              <textarea
                class="w-full mt-6 textarea"
                type="text"
                v-model="demandeAnnulation.commentaireAdmin"
              />
            </VsTd>
          </VsTr>
        </VsTable>

        <!-- Actions -->
        <div class="text-right mb-5">
          <VsButton
            @click="popupReponse = true"
            class="mr-3"
            type="border"
          >
            Mettre à jour
          </VsButton>
        </div>

        <RouterLink
          v-if="prev"
          :to="{ name: 'admin-cancellation-requests-id', params: { id: prev } }"
          class="float-left prevNext"
          @click.native="loadData"
        >
          Précedent
        </RouterLink>

        <RouterLink
          v-if="next"
          :to="{ name: 'admin-cancellation-requests-id', params: { id: next } }"
          class="float-right prevNext"
          @click.native="loadData"
        >
          Suivant
        </RouterLink>
      </VxCard>
    </div>

    <!-- Comment form popup -->
    <VsPopup
      classContent="popup-example"
      title="Argumentez réponse"
      :active.sync="popupReponse"
    >
      <p class="date-label mb-3">Commentaire</p>
      <textarea
        class="w-full textarea"
        type="text"
        v-if="demandeAnnulation"
        v-model="demandeAnnulation.commentaireAdmin"
      />
      <VsButton
        @click="update(0)"
        color="primary"
        type="filled"
        class="float-right"
      >
        Envoyer
      </VsButton>
    </VsPopup>
  </div>
</template>

<script>
import axios from '@/axios';
import moment from 'moment';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import VxTimeline from '@/components/timeline/VxTimeline';
import VueApexCharts from 'vue-apexcharts';
import adminAnalytics from '../adminAnalytics';
import Textarea from '@/views/forms/form-elements/textarea/Textarea';

export default {
  components: {
    Textarea,
    Datepicker,
    swiper,
    swiperSlide,
    vSelect,
    flatPickr,
    VxTimeline,
    VueApexCharts,
    adminAnalytics,
  },
  props: ['id'],
  filters: {
    ouiNonFilter(value) {
      if (value == true) return 'Oui';
      return 'Non';
    },
    formDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm:ss');
      }
    },
    statutConvert(value) {
      switch (value) {
        case 'A traiter':
          return 'warning';
        case 'Validé':
          return 'success';
        case 'Refusé':
          return 'danger';
      }
    },
  },
  data() {
    return {
      popupReponse: false,
      adminAnalytics,
      demandesStatutPie: {},
      annee: new Date().getFullYear(),
      demandeAnnulation: null,
      commandesFormule: [],
      negociateur: null,
      nbCommandesAttente: 0,
      nbCommandesCloturees: 0,
      formuleSerie: [],
      optionsStatut: ['A traiter', 'Refusé', 'Validé'],
      next: '',
      prev: '',
      idTeleconseiller: '',
    };
  },
  computed: {
    agentInformations() {
      const agent = [];
      if (this.negociateur) {
        this.negociateur.trigramme && agent.push({ title: 'Trigramme', value: this.negociateur.trigramme });
        this.negociateur.email && agent.push({ title: 'Email', value: this.negociateur.email });
        this.negociateur.lastName && agent.push({ title: 'Nom', value: this.negociateur.lastName });
        this.negociateur.firstName && agent.push({ title: 'Prénom', value: this.negociateur.firstName });
        this.negociateur.telephone && agent.push({ title: 'Téléphone', value: this.negociateur.telephone });
      }

      return agent;
    },
  },
  methods: {
    async update() {
      this.$vs.loading();
      this.popupReponse = false;
      const date = moment().format('MM/DD/YYYY  h:mm:ss a');
      const cancelledDemand = this.demandeAnnulation;

      const headers = {
        Accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      };

      const data = {
        statut: cancelledDemand.statut,
        commentaire: cancelledDemand.commentaire,
        commentaireAdmin: cancelledDemand.commentaireAdmin,
        date,
      };

      const dataRdv = {};
      if (cancelledDemand.statut == 'Validé') {
        Object.assign(dataRdv, {
          dateAnnulation: date,
          motifAnnulation: cancelledDemand.motif,
          statut: 'Annulé',
        });
      } else if (cancelledDemand.statut == 'Refusé') {
        Object.assign(dataRdv, {
          commentaire: `${cancelledDemand.rendezVous.commentaire} Demande d'annulation refusée.`,
          statut: 'Réalisé',
        });
      }

      try {
        await axios.put(`api/demande_annulations/${this.id}`, data, { headers });
        await axios.put(`api/rendez_vouses/${cancelledDemand.rendezVous.id}`, dataRdv, { headers });

        this.$vs.notify({
          title: 'Succès',
          text: "La demande d'annulation a été mise à jour avec succès",
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
          time: 5000,
        });

        if (this.next) {
          await this.$router.push({
            name: 'admin-cancellation-requests-id',
            params: { id: this.next },
          });
          this.loadData();
        } else {
          this.$router.push({ name: 'admin-cancellation-requests' });
        }
      } catch (error) {
        this.$vs.notify({
          title: 'Erreur',
          text: this.$parseError(error).message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
          time: 4000,
        });
      }

      this.$vs.loading.close();
    },
    async getDemandeAnnulation() {
      this.$vs.loading();

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      };

      const { data: cancelledDemands } = await axios.get(
        `/api/demande_annulations?id=${this.id}`,
        { headers },
      );

      const cancelledDemand = this.demandeAnnulation = cancelledDemands.shift() || null;
      const { data: mandataries } = await axios.get(
        `/api/mandataries?trigramme=${cancelledDemand.negociateur.trigramme}`,
        { headers },
      );

      if (
        !this.$store.state.AppActiveUser.user.roles.includes('ROLE_SUPER_ADMIN')
      ) {
        this.idTeleconseiller = cancelledDemand.teleconseiller.id;
        this.admin = false;
      }

      if (mandataries) {
        const mandatary = mandataries.shift();

        this.getNextPrevRecords();
        this.negociateur = mandatary || null;
        this.filterStatut();
      }

      this.$vs.loading.close();
    },
    async filterStatut() {
      const { data } = await axios.get(
        `/api/demandes/statut/${this.negociateur.id}?annee=${this.annee}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        },
      );

      this.formuleSerie = data;
    },
    async getNextPrevRecords() {
      try {
        const { data } = await axios.get(
          `/api/demandes/${this.id}/nextPrev?idTeleconseiller=${this.idTeleconseiller}`,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          },
        );

        this.next = data.next[0] ? data.next[0].id : null;
        this.prev = data.prev[0] ? data.prev[0].id : null;
      } catch (error) {
        this.$toasted.show(error).goAway(1500);
        this.$toasted
          .show('Une erreur est survenue! Veuillez actualiser la page')
          .goAway(1500);
      }
    },
    loadData() {
      this.getDemandeAnnulation();
    },
  },
  created() {
    this.loadData();
  },
};
</script>
<style>
.prevNext {
  background: #8d6bcc;
  color: #fff !important;
  padding: 5px;
  border-radius: 4px;
}
.status {
  width: 50%;
  margin-right: 10px;
  float: left;
  vertical-align: middle;
  margin-top: 10px;
}
</style>
