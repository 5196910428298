/*= ========================================================================================
  File Name: adminAnalytics.js
  Description: Data shown by charts
  ----------------------------------------------------------------------------------------
  Author: Noura toukabri
========================================================================================== */
import get from 'lodash/get';
import max from 'lodash/max';

export default {
// Commandes par statut
  commandsPie: {
    analyticsData: [],
    chartOptions: {
      labels: ['En attente', 'Non renseigné', 'En cours', 'Suspendu', 'Crédit boutique'],
      dataLabels: {
        enabled: true,
      },
      legend: { show: false },
      chart: {
        type: 'pie',
        offsetY: 0,
        dropShadow: {
          enabled: false,
          blur: 5,
          left: 1,
          top: 1,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: 2,
      },
      colors: ['#51964F',
        '#19CCDC',
        '#ED1151',
        '#e4d839',
        '#a659e4'],

    },
  },

  commandsRdvFormulePie: {
    analyticsData: [],
    chartOptions: {
      labels: [],
      dataLabels: {
        enabled: true,
      },
      legend: { show: false },
      chart: {
        type: 'pie',
        offsetY: 0,
        dropShadow: {
          enabled: false,
          blur: 5,
          left: 1,
          top: 1,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: 2,
      },
      colors: ['#ff007c', '#ead121', '#44cfe4', '#7ED67D'],

    },
  },

  // Commandes par prestataire
  commandsPrestatairePie: {
    analyticsData: [],
    chartOptions: {
      labels: [],
      dataLabels: {
        enabled: true,
      },
      legend: { show: false },
      chart: {
        type: 'pie',
        offsetY: 0,
        dropShadow: {
          enabled: false,
          blur: 5,
          left: 1,
          top: 1,
          opacity: 0.2,
        },
      },
      stroke: {
        width: 2,
      },

    },
  },
  // Demandes d'annulation par négociateur
  demandesStatutPie: {
    chartOptions: {
      colors: ['#fea500', '#eb5354', '#29c76f'],
      plotOptions: {
        bar: {
          horizontal: false,
          endingShape: 'rounded',
          columnWidth: '55%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },

      xaxis: {
        categories: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jui', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
      fill: {
        opacity: 1,

      },
      tooltip: {
        y: {
          formatter(val) {
            return `${+val} demandes d'annulation`;
          },
        },
      },
    },
  },

  // Demandes d'annulation par motif
  pieDemandesAnnulations: {
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)',
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      data: [],
    },
    series: [
      {
        name: 'Motif d\'annulation',
        type: 'pie',
        radius: '55%',
        center: ['50%', '60%'],
        color: ['#ffe543', '#dda8de', '#ff8400', '#a3eb00',
          '#f0006b', '#3c62e7'],
        data: [],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  },

  // mandats par télepro
  barChartMandat: {
    series: [{
      name: 'Sans mandats',
      data: [],
    }, {
      name: 'Avec mandats',
      data: [],
    }],
    chartOptions: {
      colors: [
        '#6bc6c0',
        '#9feae4',
      ],
      xaxis: {
        categories: [],
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          style: {
            fontSize: 9,
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -10,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
        formatter(value, { seriesIndex, dataPointIndex, w }) {
          let indices = w.config.series.map((item, i) => i);
          indices = indices.filter((i) => !w.globals.collapsedSeriesIndices.includes(i) && get(w.config.series, `${i}.data.${dataPointIndex}`) > 0);
          if (seriesIndex == max(indices)) {
            return w.globals.stackedSeriesTotals[dataPointIndex];
          }

          return '';
        },
      },

      chart: {
        type: 'bar',
        height: 500,
        stacked: true,
      },
      zoom: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: 'right',
        offsetX: 0,
        offsetY: 50,
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      }],
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'top',
          },
        },
      },
    },
  },

  // rendez-vous par télepro
  barChart: {
    series: [{
      name: 'Nb Rendez-vous',
      data: [],
    }],
    chartOptions: {
      colors: ['#c73b71',
        '#eabc21',
        '#FF9F43',
        '#05d8de',
        '#0bc724',
        '#bf81de',
        '#ffc0c1',
        '#1ea2de',
        '#23b6c7',
        '#e5ea50',
        '#ff2677',
        '#57de23'],
      plotOptions: {
        bar: {
          horizontal: false,
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [],
      },

    },
  },

  TcChart: {

    series: [{
      name:
        "En attente d'annulation",
      data: [0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 12, 1, 1],
    }, {
      name: 'A venir',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
    }, {
      name: 'Annulés',
      data: [48, 1, 7, 0, 0, 0, 0, 0, 38, 40, 0, 37, 20, 5, 0, 5],
    }, {
      name:
        'A statuer',
      data: [3, 2, 7, 0, 0, 0, 0, 0, 19, 3, 0, 28, 8, 103, 7, 12],
    },
    {
      name:
          'Réalisés',
      data: [69, 0, 6, 0, 0, 0, 2, 0, 70, 69, 0, 81, 22, 19, 10, 11],
    }],
    chartOptions: {
      colors: ['#dda8de',
        '#e4dd56',
        '#44cfe4',
        '#bcee8a',
        '#f0c77a',

      ],
      xaxis: {
        legend: {
          offsetY: -45,
        },
        categories: [],
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          style: {
            fontSize: 10,
          },
        },
      },
      yaxis: {
        show: true,
        showAlways: true,
        showForNullSeries: true,
      },
      dataLabels: {
        enabled: true,
        offsetY: -10,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
        allowOverlap: true,
        formatter(value, { seriesIndex, dataPointIndex, w }) {
          let indices = w.config.series.map((item, i) => i);
          indices = indices.filter((i) => !w.globals.collapsedSeriesIndices.includes(i) && get(w.config.series, `${i}.data.${dataPointIndex}`) > 0);
          if (seriesIndex == max(indices)) {
            return w.globals.stackedSeriesTotals[dataPointIndex];
          } return '';
        },
      },

      chart: {
        type: 'bar',
        height: 500,
        stacked: true,
      },
      zoom: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: 'right',
        offsetX: 0,
        offsetY: 50,
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      }],
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'top',
          },
        },
      },
    },
  },
  totalTcChart: {
    series: [],
    chartOptions: {
      labels: [],
      colors: ['#dda8de',
        '#e4dd56',
        '#44cfe4',
        '#bcee8a',
        '#f0c77a',
      ],
      plotOptions: {
        pie: {
          customScale: 0.8,
        },
      },
      legend: {
        position: 'bottom',
      },
    },
  },
  totalRdvRealiseChart: {
    series: [],
    chartOptions: {
      labels: [],
      colors: [
        '#6bc6c0',
        '#9feae4',
      ],
      plotOptions: {
        pie: {
          customScale: 0.8,
        },
      },
      legend: {
        position: 'bottom',
      },
    },
  },
  annulationsTcChart: {
    series: [{
      data: [],
      name: '',
    }, {
      data: [],
      name: '',
    }, {
      data: [],
      name: '',
    }, {
      data: [],
      name: '',
    }, {
      data: [],
      name: '',
    }, {
      data: [],
      name: '',
    }],
    chartOptions: {
      colors: ['#ff8954',
        '#e4da00',
        '#44cfe4',
        '#8ceea7',
        '#f05890',
        '#4bade7',
      ],
      xaxis: {
        categories: [],
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          style: {
            fontSize: 10,
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -10,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
        formatter(value, { seriesIndex, dataPointIndex, w }) {
          let indices = w.config.series.map((item, i) => i);
          indices = indices.filter((i) => !w.globals.collapsedSeriesIndices.includes(i) && get(w.config.series, `${i}.data.${dataPointIndex}`) > 0);
          if (seriesIndex == max(indices)) {
            return w.globals.stackedSeriesTotals[dataPointIndex];
          }

          return '';
        },
      },

      chart: {
        type: 'bar',
        height: 500,
        stacked: true,
      },
      zoom: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: 'right',
        offsetX: 0,
        offsetY: 50,
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      }],
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'top',
          },
        },
      },
    },
  },
};
